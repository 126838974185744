export const ContactDetails = () => {
  return (
    <div className='row tile--wrapper'>
      <div className='tile col-sm-12 col-md-4'>
        <a href='tel: +48 602 590 435'>
          <div className='icon'>
            <img src='./ph.svg' alt='Telefon' />
          </div>
          <span>+48 602 590 435</span>
        </a>
      </div>
      <div className='tile col-sm-12 col-md-4' style={{ textAlign: 'center' }}>
        <a target='_blank' href=''>
          <div className='icon'>
            <img src='./localization.svg' alt='Lokalizacja' />
          </div>
          <span>
            ul. Gołębia 4<br />
            26-601 Bielicha
            <br />
            Polska
          </span>
        </a>
      </div>
      <div className='tile col-sm-12 col-md-4' style={{ textAlign: 'center' }}>
        <a target='_blank' href='mailto:biuro@stilko.pro'>
          <div className='icon'>
            <img src='./email.svg' alt='Email' />
          </div>
          <span>biuro@stilko.pro</span>
        </a>
      </div>
    </div>
  )
}
