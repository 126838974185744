export const AboutUs = ({ hideMap }: any) => {
  return (
    <div className='map col-sm-12 col-md-6'>
      <div>
        <a href='/#about' id='about' className='section-header'>
          O nas
        </a>
        <div>
          Producent kotew do okien, kotwy do drewna i blacha montażowa do okien.
          Jesteśmy rodzinną firmą produkcyjną z 30 letnim doświadczeniem w
          obróbce metali. Nasze produkty są doceniane przez naszych Klientów z
          którymi współpracujemy od wielu lat, dzięki wysokiej jakości wykonania
          kotew montażowych, kołków oraz metalowych akcesoriów do mebli,
          gwarantujemy zadowolenie i długą żywotność produktów. Firma STILKO
          jako producent kotwy w swojej ofercie posiada pozycje tj.: kotwa do
          okien PCV, kotwa do okien aluminiowych, kotwa do drewna - kotwa
          wbijana, kotwa obrotowa. Montaż kotwy dzięki odpowiednio
          rozmieszczonym otworom jest przyjemniejszy dla montażystów oraz
          zapewnia lepsze trzymanie okna i drzwi. Cenimy sobie jakość i
          przyjazną współpracę. <br />
          <u>Uważamy, że świetnie wykonana praca jest najlepszą reklamą.</u>
          <br />
          <br />
          Realizujemy również indywidualne projekty, według pożądanej
          specyfikacji. Zapraszamy do kontaktu za pomocą telefonu lub formularza
          kontaktowego.
        </div>
      </div>
      {!hideMap && (
        <div className='map-wrapper'>
          <a
            target='_blank'
            href='https://www.google.com/maps/place/STILKO+Monika+Fo%C5%82tyn/@51.4045971,21.114648,14.5z/data=!4m13!1m7!3m6!1s0x471859a88238ea31:0x302ad0e8848c83b9!2sBielicha+92A,+26-601+Bielicha!3b1!8m2!3d51.4174221!4d21.084609!3m4!1s0x47185924c62ce7c5:0xfc067ced0375085b!8m2!3d51.4173088!4d21.0845403'
          >
            <img width='80%' src='./map.jpeg' alt='Stilko Mapa' />
          </a>
        </div>
      )}
    </div>
  )
}
