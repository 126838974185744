import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

export const Navbar = () => {
  const [showSidebar, setShowSidebar] = useState(false)
  const navigate = useNavigate()

  return (
    <nav className='navbar navbar navbar-light navbar-expand-lg'>
      <button
        type='button'
        aria-label='Toggle navigation'
        className='navbar-toggler'
      >
        <span
          className='navbar-toggler-icon'
          onClick={() => setShowSidebar(prev => !prev)}
        ></span>
      </button>
      <div className='navbar-brand brand'>
        <div
          onClick={() => () => window.location.replace('/')}
          className='logo--wrapper-special'
        >
          <img src='./logo_small.svg' alt='logo stilko' className='logo' />
          <div>
            <span className='title'>STILKO</span>
            <p className='logo__description'>i wszystko w metalu</p>
          </div>
        </div>
      </div>
      <div
        id='nav-collapse'
        className='navbar-collapse collapse'
        style={{ display: 'none' }}
      >
        <ul className='navbar-nav ml-auto'>
          <ul className='navbar-nav'>
            <li className='nav-item'>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/')}
                target='_self'
                className='nav-link'
              >
                Home
              </a>
            </li>
            <li className='nav-item'>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/products')}
                target='_self'
                className='nav-link'
              >
                Produkty
              </a>
            </li>
            <li className='nav-item'>
              <a
                style={{ cursor: 'pointer' }}
                onClick={() => navigate('/about')}
                target='_self'
                className='nav-link'
              >
                O nas
              </a>
            </li>
          </ul>
          <li className='nav-item special-link'>
            <a
              style={{ cursor: 'pointer' }}
              onClick={() => navigate('/contact')}
              target='_self'
              className='nav-link'
            >
              Kontakt
            </a>
          </li>
        </ul>
      </div>
    </nav>
  )
}
