import { AboutUs } from '../components/AboutUs'
import { ContactDetails } from '../components/ContactDetails'
import ContactForm from '../components/ContactForm'
import { Lending } from '../components/Lending'
import { Navbar } from '../components/Navbar'
import { Products } from '../components/Products'

export const LendingPage = () => {
  return (
    <div data-server-rendered='true' id='__nuxt'>
      <div id='__layout'>
        <div>
          <Navbar />
          <main className='main-content'>
            <div>
              <Lending />
              <Products />
              <section id='contact' className='contact'>
                <div className='contact col-sm-12 col-md-6'>
                  <ContactForm />
                  <ContactDetails />
                </div>
                <AboutUs />
              </section>
            </div>
          </main>
        </div>
      </div>
    </div>
  )
}
