import { AboutUs } from '../components/AboutUs'
import { ContactDetails } from '../components/ContactDetails'
import ContactForm from '../components/ContactForm'
import { Lending } from '../components/Lending'
import { Navbar } from '../components/Navbar'

export const ContactPage = () => {
  return (
    <div data-server-rendered='true' id='__nuxt'>
      <div id='__layout'>
        <div>
          <Navbar />
          {/* <Lending /> */}
          <main className='main-content'>
            <section id='home' className='container-margin intro-jumbotron'>
              <section
                id='contact'
                className='contact'
                style={{ marginTop: '20vh' }}
              >
                <div className='contact col-sm-12 col-md-6'>
                  <ContactForm />
                </div>
                <ContactDetails />
                <div className='map-wrapper'></div>
              </section>
            </section>
          </main>
        </div>
      </div>
    </div>
  )
}
