import Axios from 'axios'
import React, { useState } from 'react'

function ContactForm () {
  const [formSentSuccessfully, setFormSentSuccessfully] = useState(false)
  const [formName, setFormName] = useState('')
  const [formEmail, setFormEmail] = useState('')
  const [formMessage, setFormMessage] = useState('')
  const [isSending, setIsSending] = useState(false)

  const sendEmail = async (event: any) => {
    event.preventDefault()
    setIsSending(true)
    try {
      await Axios.post('https://jan-mat-email-sender.onrender.com/send-email', {
        from: formEmail,
        to: 'biuro@stilko.pro',
        text: formMessage + '\n' + formEmail + ' ' + formName,
        token: 'MESSAGE_TOKEN'
      })
      setFormSentSuccessfully(true)
    } catch (e) {
      console.error(e)
      window.alert('Wystąpił błąd, prosimy o wybranie innej formy kontaktu.')
    }
    setIsSending(false)
  }

  if (formSentSuccessfully) {
    return (
      <div className='contact-form--wrapper' id='ct-form'>
        <h2 className='contact--header'>Dziękujemy za wysłanie maila!</h2>
        <p className='contact--description'>
          Skontaktujemy sie z Panem/Panią jak najszybciej
        </p>
      </div>
    )
  }

  return (
    <div className='contact-form--wrapper' id='ct-form'>
      <h2 className='contact--header'>Złóż zamówienie / zapytanie</h2>
      <p className='contact--description'>Napisz do nas maila</p>
      <form className='contact--form' onSubmit={e => sendEmail(e)}>
        <input
          value={formName}
          onChange={e => setFormName(e.target.value)}
          type='text'
          name='name'
          className='contact--input'
          placeholder='Imie i nazwisko'
        />
        <span className='contact-field__info'>Minimum 5 znaków</span>
        <input
          value={formEmail}
          onChange={e => setFormEmail(e.target.value.trim())}
          name='email'
          type='email'
          className='contact--input'
          placeholder='Adres email'
        />
        <span className='contact-field__info'>Minimum 7 znaków</span>
        <textarea
          value={formMessage}
          onChange={e => setFormMessage(e.target.value)}
          name='message'
          className='contact--input'
          rows={5}
          placeholder='Wiadomość'
        ></textarea>
        <span className='contact-field__info last'>Minimum 10 znaków</span>
        <button className='contact--submit' type='submit' disabled={isSending}>
          Wyślij
        </button>
      </form>
      <p>
        Administratorem danych osobowych jest stilko, ul. Gołebia, 26-601,
        Bielicha, Polska. Dane będą przetwarzane w celu zapewnienia najwyższej
        jakości usług. Podanie danych jest dobrowolne, ale niezbędne do
        przetworzenia zapytania. Osoba, której dane dotyczą, ma prawo dostępu do
        treści swoich danych oraz ich poprawiania.
      </p>
    </div>
  )
}

export default ContactForm
