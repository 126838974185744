import { Navbar } from '../components/Navbar'
import { Products } from '../components/Products'

export const ProductsPage = () => {
  return (
    <div data-server-rendered='true' id='__nuxt'>
      <div id='__layout'>
        <div>
          <Navbar />
          <main className='main-content'>
            <section id='home' className='container-margin intro-jumbotron'>
              <div style={{ marginTop: '20vh' }}>
                <Products />
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>
  )
}
