export const Lending = () => {
  return (
    <section id='home' className='container-margin intro-jumbotron'>
      <div className='row header-bg'>
        <div className='col'>
          <div
            onClick={() => () => window.location.replace('/')}
            className='logo--wrapper'
          >
            <img src='./logo_small.svg' alt='logo stilko' className='logo' />
            <div>
              <span className='title'>STILKO</span>
              <p className='logo__description'>i wszystko w metalu</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
