export const Products = () => {
  return (
    <section className='container-margin products'>
      <a href='/#products' id='products' className='section-header'>
        Produkty
      </a>
      <div className='row'>
        <div className='col-sm-6 col-md-3'>
          <div className='product' data-v-7e22399e>
            <div className='photo special' data-v-7e22399e>
              <img src='./kotwy-rb.png' alt='Kotwy montażowe' data-v-7e22399e />
            </div>
            <h4 className='name' data-v-7e22399e>
              Kotwy montażowe
            </h4>
            <p className='description' data-v-7e22399e>
              Kotwy do okien PCV, okien drewnianych, okien aluminiowych. Jako
              producent kotew używamy sprawdzonej jakości blachy ocynkowanej
              grubość 1,25 mm oraz 1,50 mm. Wytwarzamy również kotwy okienne
              długie. Produkujemy kotwy do wszystkich systemów okiennych tj.:
              Aluplast, Veka, Schuco, Rehau, KBE, Trocal, Salamander, Gealan,
              Thyssen, Deceuninck, Kommerling, Brugmann, Decco, Avantgarde.
              Kotwy obrotowe do systemów: Aluplast, Iglo, Schuco, Rehau.
              Realizujemy również indywidualne projekty.
            </p>
          </div>
        </div>
        <div className='col-sm-6 col-md-3'>
          <div className='product' data-v-7e22399e>
            <div className='photo' data-v-7e22399e>
              <img src='./kolki-rb.png' alt='Kołki montażowe' data-v-7e22399e />
            </div>
            <h4 className='name' data-v-7e22399e>
              Kołki montażowe
            </h4>
            <p className='description' data-v-7e22399e>
              Kołki szybki montaż 8x45, 8x60, 8x80 oraz wiele innych
            </p>
          </div>
        </div>
        <div className='col-sm-6 col-md-3'>
          <div className='product' data-v-7e22399e>
            <div className='photo' data-v-7e22399e>
              <img src='./dyble-rb.png' alt='Dyble metalowe' data-v-7e22399e />
            </div>
            <h4 className='name' data-v-7e22399e>
              Dyble metalowe
            </h4>
            <p className='description' data-v-7e22399e>
              Dyble montażowe do okien i drzwi - dostępne w różnych rozmiarach
            </p>
          </div>
        </div>
        <div className='col-sm-6 col-md-3'>
          <div className='product' data-v-7e22399e>
            <div className='photo' data-v-7e22399e>
              <img
                src='./meblowe-rb.png'
                alt='Akcesoria do mebli'
                data-v-7e22399e
              />
            </div>
            <h4 className='name' data-v-7e22399e>
              Akcesoria do mebli
            </h4>
            <p className='description' data-v-7e22399e>
              Produkujemy akcesoria do mebli między innymi zawiasy, rolki, haki,
              wieszaki...
            </p>
          </div>
        </div>
      </div>
    </section>
  )
}
