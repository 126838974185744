import './App.css'
import { LendingPage } from './pages/LendingPage'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ProductsPage } from './pages/ProductsPage'
import { ContactPage } from './pages/ContactPage'
import { AboutPage } from './pages/AboutPage'

export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<LendingPage />} />
        <Route path='/products' element={<ProductsPage />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/contact' element={<ContactPage />} />
        <Route path='*' element={<Navigate to='/' replace />} />
      </Routes>
    </BrowserRouter>
  )
}
