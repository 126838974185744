import { AboutUs } from '../components/AboutUs'
import { Navbar } from '../components/Navbar'

export const AboutPage = () => {
  return (
    <div data-server-rendered='true' id='__nuxt'>
      <div id='__layout'>
        <div>
          <Navbar />
          <main className='main-content'>
            <section id='home' className='container-margin intro-jumbotron'>
              <section
                id='contact'
                className='contact'
                style={{ marginTop: '15vh' }}
              >
                <a
                  target='_blank'
                  href='https://www.google.com/maps/place/STILKO+Monika+Fo%C5%82tyn/@51.4045971,21.114648,14.5z/data=!4m13!1m7!3m6!1s0x471859a88238ea31:0x302ad0e8848c83b9!2sBielicha+92A,+26-601+Bielicha!3b1!8m2!3d51.4174221!4d21.084609!3m4!1s0x47185924c62ce7c5:0xfc067ced0375085b!8m2!3d51.4173088!4d21.0845403'
                >
                  <img width='80%' src='./map.jpeg' alt='Stilko Mapa' />
                </a>
                <AboutUs hideMap />
              </section>
            </section>
          </main>
        </div>
      </div>
    </div>
  )
}
